import { Link as RemixLink } from '@remix-run/react';
import { useRequireFullDocumentLoadContext } from '../contexts/requireFullDocumentLoadContext';
import type { LinkProps } from '@remix-run/react';

export const Link = ({ to, children, ...attr }: LinkProps) => {
  const requireFullDocumentLoad = useRequireFullDocumentLoadContext();

  if (requireFullDocumentLoad) {
    return (
      <a {...attr} href={to.toString()}>
        {children}
      </a>
    );
  }

  return (
    <RemixLink {...attr} to={to}>
      {children}
    </RemixLink>
  );
};
